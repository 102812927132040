import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Testimonials from "../components/Testimonials"
import { filterByLocale } from "../helpers"

const SocialMediaLandingPage = props => {
  console.log(props)
  const landingPageImages = props.data.socialMediaImages.edges

  const currentLocale = "pt-BR"
  const testimonials = filterByLocale(props.data.testimonial, currentLocale)

  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Social Media"
        description={
          "Esta página apresenta meu trabalho como Designer " +
          "Gráfico para redes sociais, com artes e meu processo criativo"
        }
        image={props.data.socialCoverImage.publicURL}
      />
      <div className="bg-gray-100 min-h-screen">
        <header className="bg-blue-500 text-white text-center py-4">
          <h1 className="text-4xl font-bold">
            Professional Logo Design Services
          </h1>
          <p className="mt-2">
            Elevate your brand with a unique and memorable logo.
          </p>
        </header>
        <section className="container mx-auto py-12">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Our Services</h2>
            <p className="text-gray-700 mb-8">
              Our experienced team of designers offers a range of services to
              meet your branding needs.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-4">Custom Logo Design</h3>
              <p className="text-gray-700">
                We create tailor-made logos that reflect your brand's identity
                and values.
              </p>
            </div>
            <div className="bg-white p-6 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-4">
                Brand Identity Packages
              </h3>
              <p className="text-gray-700">
                Comprehensive packages including logos, business cards, and more
                for a cohesive brand presence.
              </p>
            </div>
            <div className="bg-white p-6 rounded-md shadow-md">
              <h3 className="text-xl font-semibold mb-4">Logo Redesign</h3>
              <p className="text-gray-700">
                Give your existing logo a fresh look to stay relevant and
                appealing to your audience.
              </p>
            </div>
          </div>
        </section>
        <section className="bg-gray-200 py-12">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Portfolio</h2>
            <p className="text-gray-700 mb-8">
              Explore some of our recent logo design projects that showcase our
              creativity and expertise.
            </p>
            {/* Add your portfolio items (images, descriptions, etc.) here */}
          </div>
        </section>
        <section id="contact" className="container mx-auto py-12">
          <div className="max-w-lg mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Get in Touch</h2>
            <p className="text-gray-700 mb-8">
              Ready to take the next step? Contact us to discuss your logo
              design needs.
            </p>
            <a
              href="#"
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-full inline-block hover:bg-blue-600 transition duration-300"
            >
              Contact Us
            </a>
          </div>
        </section>
        <footer className="bg-gray-800 text-white text-center py-4">
          <p>&copy; 2023 Logo Design Services. All rights reserved.</p>
        </footer>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    socialMediaImages: allFile(
      filter: { absolutePath: { regex: "/landing-page-redes-sociais/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
    testimonial: allContentfulTestimonial {
      nodes {
        company
        name
        node_locale
        title
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    socialCoverImage: file(
      relativePath: { eq: "bellafronte-studio-social-cover.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
  }
`

export default SocialMediaLandingPage
